<template>
  <div class="backup-page">
    <AppPreview
      title="Резервное копирование"
      text="С каждым днем повышается объем хранимых данных, потеря которых может стать проблемой для IT-инфраструктуры бизнеса."
    >
      <img src="/images/preview121.png" alt="preview">
    </AppPreview>

    <section class="why">
      <div class="container">
        <div class="why__content">
          <h2>Зачем IT-компаниям использовать резервное копирование?</h2>
          <p class="why__text">
            Вместе с повышением требований к непрерывности работы растет и объем хранимых данных, что становится проблемой для IT-инфраструктуры бизнеса. Чем больше бизнес зависит от IT-систем, тем большие убытки он понесет в случае потери данных и простоя, пока идет восстановление работоспособности.
          </p>
        </div>
      </div>
    </section>

    <section class="rules">
      <div class="container">
        <h2>Правила хорошего Бэкапа</h2>
        <ul class="rules-list">
          <li
            v-for="(rule, index) of rules"
            :key="index"
          >
            {{ rule }}
          </li>
        </ul>
      </div>
    </section>

    <section class="advantages">
      <div class="container">
        <h2>Преимущества выбора Cloud bit</h2>

        <div class="advantages__list">
          <AppAdvantage
            v-for="(advantage, index) of advantages"
            :key="index"
            :image="advantage.image"
            :title="advantage.title"
            :text="advantage.text"
          />
        </div>
      </div>
    </section>

    <section class="backup-section">
      <div class="container">
        <h2>{{ extraData.title }}</h2>

        <div class="desc">
          {{ extraData.desc }}
        </div>

        <div class="calc">
          <div
            v-for="(item, index) of items"
            :key="index"
            class="calc-row"
          >
            <p class="calc-row__title">{{ item.title }}</p>
            <BaseInputRange
              v-model="item.model"
              :min="item.min"
              :max="item.max"
              :step="item.step"
              class="calc-row__slider"
            />
            <p class="calc-row__val">{{ item.model }} {{ item.unit }}</p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="container"
      style="margin-top: 60px;"
    >
      <PanelTotal
        :price="total"
        @open="$refs.requestModal.open()"
      />
    </section>

    <ModalRequest
      :total="total"
      ref="requestModal"
      @submit="onSubmit"
    >
      <AppReceipt
        :receipt="receipt"
      />
    </ModalRequest>

    <ModalThank ref="modalThank" />
  </div>
</template>

<script>
import useBackup from '@/composables/useBackup'

import AppPreview from '@/components/AppPreview'
import AppAdvantage from '@/components/AppAdvantage'
import BaseInputRange from '@/components/Base/BaseInputRange'
import PanelTotal from '@/components/PanelTotal'
import ModalRequest from '@/components/ModalTemplates/ModalRequest'
import ModalThank from '@/components/ModalTemplates/ModalThank'
import AppReceipt from '@/components/AppReceipt'

export default {
  name: 'PageBackup',
  components: {
    AppPreview,
    AppAdvantage,
    BaseInputRange,
    PanelTotal,
    ModalRequest,
    ModalThank,
    AppReceipt
  },
  setup () {
    const {
      receipt,
      total,
      extraData,
      items,
      requestBody
    } = useBackup()

    return {
      receipt,
      total,
      extraData,
      items,
      requestBody
    }
  },
  data () {
    return {
      rules: [
        'Иметь по меньшей мере три копии данных',
        'Хранить копии на двух разных носителях',
        'Хранить одну резервную копию на удаленной площадке'
      ],
      advantages: [
        {
          image: '/images/adv5.svg',
          title: 'Снижение RTO и RPO ',
          text: 'Допустимое время простоя сервиса в случае сбоя и объем возможных потерь данных в случае сбоя).'
        },
        {
          image: '/images/adv6.svg',
          title: 'Уменьшение капитальных затрат',
          text: 'Уменьшение капитальных затрат на оборудование и его эксплуатацию ― все данные хранятся в облаке.'
        },
        {
          image: '/images/adv7.svg',
          title: 'Снижение затрат на ПО',
          text: 'Возможность гибко настроить конфигурацию. Оплачиваются только необходимые ресурсы.'
        }
      ]
    }
  },
  methods: {
    async onSubmit (e) {
      const { surname, name, patron, companyName, email, phone } = e

      try {
        const res = await this.$api.common.sendClientData({
          surname, name, patronymic: patron, companyName, email, phone
        })
        const applicationId = res.data?.applicationId

        await this.$api.services.sendBackup({
          applicationId,
          service: this.requestBody,
          price: this.total
        })

        this.$refs.requestModal.close()
        this.$refs.requestModal.clear()
        this.$refs.modalThank.open()
      } catch (e) {
        console.log(e)
        alert('Произошла ошибка!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.backup-page {
  .why {
    padding: 40px 0;
    &__content {
      max-width: 970px;
    }
    &__text {
      font-size: 18px;
      line-height: 1.40;
      margin-top: 40px;
    }
  }

  .rules {
    padding: 40px 0;

    @media screen and (max-width: $media-esm) {
      padding: 20px 0;
    }

    &-list {
      margin-top: 40px;
      li {
        font-size: 20px;
        margin: 10px 0;
      }
    }
  }

  .advantages {
    padding: 40px 0;
    &__list {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      grid-auto-rows: 350px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $media-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $media-esm) {
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
